import { detectLocale } from '../components/i18n';

const Blog = () => {
    const locale = detectLocale() == 'fr' ? 'fr' : 'en';
    if (typeof window !== 'undefined') {
        window.location = `/${locale}/blog`;
    }

    return null;
};

export default Blog;
